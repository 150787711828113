import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsPlanOverview from './Components/BuildDetailsPlanOverview';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsPlanChanges from './Components/BuildDetailsPlanChanges';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import EntityNames from '../../../../helpers/EntityNames';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';

export enum BuildDetailsPlanKeys {
  OVERVIEW = 'Overview',
  PLAN_CHANGES = 'Plan Changes',
  SOIL_TEST = 'Soil Test',
}

type iGetBuildDetailsPlanMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iGetBuildDetailsPlanMenuItemsMap): iMenuItemsMap => {
  return {
    [BuildDetailsPlanKeys.OVERVIEW]: {
      name: BuildDetailsPlanKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsPlanKeys.OVERVIEW} />,
      component: (
        <BuildDetailsPlanOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPlanKeys.PLAN_CHANGES]: {
      name: BuildDetailsPlanKeys.PLAN_CHANGES,
      icon: (
        <Icons.EditorNumberListIcon label={BuildDetailsPlanKeys.PLAN_CHANGES} />
      ),
      component: (
        <BuildDetailsPlanChanges
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPlanKeys.SOIL_TEST]: {
      name: BuildDetailsPlanKeys.SOIL_TEST,
      icon: <Icons.JiraLabsIcon label={BuildDetailsPlanKeys.SOIL_TEST} />,
      component: (
        <>
          <AttributeSetInputPanel
            isDisabled={isDisabled}
            attributeSection={AttributeForSections.PLANS}
            entityId={build.id}
            entityName={EntityNames.Build}
            attributeSetCodes={[AttributeSetCodes.SoilTest]}
          />
        </>
      ),
    },
  };
};

const BuildDetailsPlanHelper = {
  getMenuItemsMap,
};
export default BuildDetailsPlanHelper;
